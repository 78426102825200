import * as Sentry from '@sentry/vue'
export default defineNuxtPlugin(nuxtApp => {
  const appConfig = useAppConfig()
  if (appConfig.SENTRY_DSN) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: appConfig.SENTRY_DSN,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true
        }),
        new Sentry.BrowserTracing({
          // tracePropagationTargets: ['localhost', '127.0.0.1'],
          routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router)
        })
      ],
      tracesSampleRate: 1.0
    })
    // 接口异常上报
    window.captureAxiosException = (error) => {
      Sentry.captureException(error, {
        contexts: {
          message: {
            url: error.response.config.baseURL + error.response.config.url,
            data: error.response.config.data || error.response.config.param,
            method: error.response.config.method,
            status: error.response.status,
            statusText: error.response.statusText,
            responseData: JSON.stringify(error.response.data)
          }
        }
      })
    }
    const unhandledrejection = window.onunhandledrejection
    window.onunhandledrejection = (err) => {
      // 普通的业务错误信息不上报到sentry
      if (err && err.reason && err.reason.code === 999) {
        return
      }
      unhandledrejection(err)
    }
  }
})
