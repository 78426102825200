import { default as indextZHXq1WGjiMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/book/index.vue?macro=true";
import { default as answerQuestionTableDialogFTyA5WKneTMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/book/info/answerQuestionTableDialog.vue?macro=true";
import { default as indexgpmgAaxBEmMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/book/info/index.vue?macro=true";
import { default as certificatei74fB7sDi0Meta } from "E:/project/52read/education/roncoo-education-web/pages/account/certificate.vue?macro=true";
import { default as collectnfqM6OOaKIMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/collect.vue?macro=true";
import { default as couponHnIr159Q7zMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/coupon.vue?macro=true";
import { default as coursekAwDliuIS8Meta } from "E:/project/52read/education/roncoo-education-web/pages/account/course.vue?macro=true";
import { default as gradencuekwbx87Meta } from "E:/project/52read/education/roncoo-education-web/pages/account/grade.vue?macro=true";
import { default as info5xmVss52FyMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/info.vue?macro=true";
import { default as invoiceC6tr0EJgaHMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/invoice.vue?macro=true";
import { default as indexOFK6ypyB9gMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/message/index.vue?macro=true";
import { default as note7etsUKwVwmMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/note.vue?macro=true";
import { default as indexXb741NmZr2Meta } from "E:/project/52read/education/roncoo-education-web/pages/account/order/index.vue?macro=true";
import { default as paperOVqv5zJwODMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/paper.vue?macro=true";
import { default as index1ACcFrA4oEMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/promoter/index.vue?macro=true";
import { default as registrationPaMgakQHc4Meta } from "E:/project/52read/education/roncoo-education-web/pages/account/registration.vue?macro=true";
import { default as coinInRxgNtAnzMeta } from "E:/project/52read/education/roncoo-education-web/pages/account/virtual/coin.vue?macro=true";
import { default as teacherwe8s6GOMwKMeta } from "E:/project/52read/education/roncoo-education-web/pages/apply/teacher.vue?macro=true";
import { default as listDUjgn8AXnhMeta } from "E:/project/52read/education/roncoo-education-web/pages/article/list.vue?macro=true";
import { default as viewKzhi4cog8BMeta } from "E:/project/52read/education/roncoo-education-web/pages/article/view.vue?macro=true";
import { default as analysisJA5FCKHRhRMeta } from "E:/project/52read/education/roncoo-education-web/pages/book/analysis.vue?macro=true";
import { default as exam7gN3JjvDp2Meta } from "E:/project/52read/education/roncoo-education-web/pages/book/exam.vue?macro=true";
import { default as listtErTzGDIfGMeta } from "E:/project/52read/education/roncoo-education-web/pages/book/list.vue?macro=true";
import { default as preview8aP9HsiuVFMeta } from "E:/project/52read/education/roncoo-education-web/pages/book/preview.vue?macro=true";
import { default as resultsGTjaN7YiFMeta } from "E:/project/52read/education/roncoo-education-web/pages/book/result.vue?macro=true";
import { default as view0E4xuMQMVfMeta } from "E:/project/52read/education/roncoo-education-web/pages/book/view.vue?macro=true";
import { default as audiotXHwCUedinMeta } from "E:/project/52read/education/roncoo-education-web/pages/course/audio.vue?macro=true";
import { default as combineRWERwd3xfgMeta } from "E:/project/52read/education/roncoo-education-web/pages/course/combine.vue?macro=true";
import { default as graphiciUjibSjHvUMeta } from "E:/project/52read/education/roncoo-education-web/pages/course/graphic.vue?macro=true";
import { default as listpP32mPzaz7Meta } from "E:/project/52read/education/roncoo-education-web/pages/course/list.vue?macro=true";
import { default as live4WZhrr9zseMeta } from "E:/project/52read/education/roncoo-education-web/pages/course/live.vue?macro=true";
import { default as singleaaAIuZK3ONMeta } from "E:/project/52read/education/roncoo-education-web/pages/course/single.vue?macro=true";
import { default as subjectNzzaDHgAPEMeta } from "E:/project/52read/education/roncoo-education-web/pages/course/subject.vue?macro=true";
import { default as topicF3XUswmGf7Meta } from "E:/project/52read/education/roncoo-education-web/pages/course/topic.vue?macro=true";
import { default as videobZnJfT3coYMeta } from "E:/project/52read/education/roncoo-education-web/pages/course/video.vue?macro=true";
import { default as analysisGoq6pFGQBiMeta } from "E:/project/52read/education/roncoo-education-web/pages/exam/analysis.vue?macro=true";
import { default as emigratedPlXFX6wsehMeta } from "E:/project/52read/education/roncoo-education-web/pages/exam/emigrated.vue?macro=true";
import { default as indexgpzaksNXNjMeta } from "E:/project/52read/education/roncoo-education-web/pages/exam/index.vue?macro=true";
import { default as list1YkTvnZPKeMeta } from "E:/project/52read/education/roncoo-education-web/pages/exam/list.vue?macro=true";
import { default as resultyRvZlR5ZKTMeta } from "E:/project/52read/education/roncoo-education-web/pages/exam/result.vue?macro=true";
import { default as startExamcJ7o7lHhnTMeta } from "E:/project/52read/education/roncoo-education-web/pages/exam/startExam.vue?macro=true";
import { default as homeworkvE4b8xvn3jMeta } from "E:/project/52read/education/roncoo-education-web/pages/grade/homework.vue?macro=true";
import { default as listqmjfxhIvWkMeta } from "E:/project/52read/education/roncoo-education-web/pages/grade/list.vue?macro=true";
import { default as practicenM5sndZTG9Meta } from "E:/project/52read/education/roncoo-education-web/pages/grade/practice.vue?macro=true";
import { default as view0mTTbTUgzlMeta } from "E:/project/52read/education/roncoo-education-web/pages/grade/view.vue?macro=true";
import { default as indexN9fhr6eGyOMeta } from "E:/project/52read/education/roncoo-education-web/pages/index.vue?macro=true";
import { default as agreementHLlDcWpgXSMeta } from "E:/project/52read/education/roncoo-education-web/pages/login/agreement.vue?macro=true";
import { default as indexUmxwYXuAO5Meta } from "E:/project/52read/education/roncoo-education-web/pages/login/index.vue?macro=true";
import { default as emigratedoqAer11npJMeta } from "E:/project/52read/education/roncoo-education-web/pages/paper/emigrated.vue?macro=true";
import { default as indextikMNGEkPCMeta } from "E:/project/52read/education/roncoo-education-web/pages/paper/index.vue?macro=true";
import { default as listewfXYxYqyXMeta } from "E:/project/52read/education/roncoo-education-web/pages/paper/list.vue?macro=true";
import { default as viewVjOteSB63mMeta } from "E:/project/52read/education/roncoo-education-web/pages/paper/view.vue?macro=true";
import { default as buyv1TkGCTb4VMeta } from "E:/project/52read/education/roncoo-education-web/pages/pay/buy.vue?macro=true";
import { default as paySuccessAxSq1vyvrjMeta } from "E:/project/52read/education/roncoo-education-web/pages/pay/paySuccess.vue?macro=true";
import { default as indexcOdh6QjVaKMeta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/course/index.vue?macro=true";
import { default as courseListjjzltD0vwFMeta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/courseList.vue?macro=true";
import { default as indexsLGhDQ2dvVMeta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/grade/index.vue?macro=true";
import { default as gradeListf8F6WarG8QMeta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/gradeList.vue?macro=true";
import { default as listSk19kfItD7Meta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/list.vue?macro=true";
import { default as indexj62bAwnJojMeta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/paper/index.vue?macro=true";
import { default as paperListjq23FGeTMoMeta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/paperList.vue?macro=true";
import { default as vipCardOaQXsBXDs6Meta } from "E:/project/52read/education/roncoo-education-web/pages/popularize/vipCard.vue?macro=true";
import { default as analysisDjOyyYF5jtMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/analysis.vue?macro=true";
import { default as dailyEB71bbXAMTMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/daily.vue?macro=true";
import { default as dailyProblemOfI90GMebZMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/dailyProblem.vue?macro=true";
import { default as errorUl9qftfQPfMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/error.vue?macro=true";
import { default as errorAnalysisVWUAx8hEMRMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/errorAnalysis.vue?macro=true";
import { default as errorProblemWBHg9q36WUMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/errorProblem.vue?macro=true";
import { default as errorResultPbtEBIAKYtMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/errorResult.vue?macro=true";
import { default as resultNrtLH5hbM9Meta } from "E:/project/52read/education/roncoo-education-web/pages/practice/result.vue?macro=true";
import { default as startPracticeTAXslAyg1gMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/startPractice.vue?macro=true";
import { default as viewF4CWr71QCOMeta } from "E:/project/52read/education/roncoo-education-web/pages/practice/view.vue?macro=true";
import { default as listctwI3ZCQ4kMeta } from "E:/project/52read/education/roncoo-education-web/pages/press/list.vue?macro=true";
import { default as viewtW0PAEvfE8Meta } from "E:/project/52read/education/roncoo-education-web/pages/press/view.vue?macro=true";
import { default as indexBON6EpZfSkMeta } from "E:/project/52read/education/roncoo-education-web/pages/recharge/index.vue?macro=true";
import { default as indexrztUJArRM6Meta } from "E:/project/52read/education/roncoo-education-web/pages/register/index.vue?macro=true";
import { default as examghIJ8hfmb6Meta } from "E:/project/52read/education/roncoo-education-web/pages/registration/exam.vue?macro=true";
import { default as result8qL7nCW1WXMeta } from "E:/project/52read/education/roncoo-education-web/pages/registration/result.vue?macro=true";
import { default as startExamDOCkxF6QeDMeta } from "E:/project/52read/education/roncoo-education-web/pages/registration/startExam.vue?macro=true";
import { default as viewJCHH2RZ5q3Meta } from "E:/project/52read/education/roncoo-education-web/pages/registration/view.vue?macro=true";
import { default as index0tpStnYnmMMeta } from "E:/project/52read/education/roncoo-education-web/pages/resetpwd/index.vue?macro=true";
import { default as indexTvCAQQdwP3Meta } from "E:/project/52read/education/roncoo-education-web/pages/search/index.vue?macro=true";
import { default as listiAoQhqcxjwMeta } from "E:/project/52read/education/roncoo-education-web/pages/tch/list.vue?macro=true";
import { default as viewpRkYBeN6DbMeta } from "E:/project/52read/education/roncoo-education-web/pages/tch/view.vue?macro=true";
import { default as index2UpHV38OKrMeta } from "E:/project/52read/education/roncoo-education-web/pages/vip/index.vue?macro=true";
import { default as success89umAzd0FAMeta } from "E:/project/52read/education/roncoo-education-web/pages/wx/success.vue?macro=true";
import { default as wx3fM6Jf4G3EMeta } from "E:/project/52read/education/roncoo-education-web/pages/wx/wx.vue?macro=true";
export default [
  {
    name: indextZHXq1WGjiMeta?.name ?? "account-book",
    path: indextZHXq1WGjiMeta?.path ?? "/account/book",
    meta: indextZHXq1WGjiMeta || {},
    alias: indextZHXq1WGjiMeta?.alias || [],
    redirect: indextZHXq1WGjiMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/book/index.vue").then(m => m.default || m)
  },
  {
    name: answerQuestionTableDialogFTyA5WKneTMeta?.name ?? "account-book-info-answerQuestionTableDialog",
    path: answerQuestionTableDialogFTyA5WKneTMeta?.path ?? "/account/book/info/answerQuestionTableDialog",
    meta: answerQuestionTableDialogFTyA5WKneTMeta || {},
    alias: answerQuestionTableDialogFTyA5WKneTMeta?.alias || [],
    redirect: answerQuestionTableDialogFTyA5WKneTMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/book/info/answerQuestionTableDialog.vue").then(m => m.default || m)
  },
  {
    name: indexgpmgAaxBEmMeta?.name ?? "account-book-info",
    path: indexgpmgAaxBEmMeta?.path ?? "/account/book/info",
    meta: indexgpmgAaxBEmMeta || {},
    alias: indexgpmgAaxBEmMeta?.alias || [],
    redirect: indexgpmgAaxBEmMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/book/info/index.vue").then(m => m.default || m)
  },
  {
    name: certificatei74fB7sDi0Meta?.name ?? "account-certificate",
    path: certificatei74fB7sDi0Meta?.path ?? "/account/certificate",
    meta: certificatei74fB7sDi0Meta || {},
    alias: certificatei74fB7sDi0Meta?.alias || [],
    redirect: certificatei74fB7sDi0Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/certificate.vue").then(m => m.default || m)
  },
  {
    name: collectnfqM6OOaKIMeta?.name ?? "account-collect",
    path: collectnfqM6OOaKIMeta?.path ?? "/account/collect",
    meta: collectnfqM6OOaKIMeta || {},
    alias: collectnfqM6OOaKIMeta?.alias || [],
    redirect: collectnfqM6OOaKIMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/collect.vue").then(m => m.default || m)
  },
  {
    name: couponHnIr159Q7zMeta?.name ?? "account-coupon",
    path: couponHnIr159Q7zMeta?.path ?? "/account/coupon",
    meta: couponHnIr159Q7zMeta || {},
    alias: couponHnIr159Q7zMeta?.alias || [],
    redirect: couponHnIr159Q7zMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/coupon.vue").then(m => m.default || m)
  },
  {
    name: coursekAwDliuIS8Meta?.name ?? "account-course",
    path: coursekAwDliuIS8Meta?.path ?? "/account/course",
    meta: coursekAwDliuIS8Meta || {},
    alias: coursekAwDliuIS8Meta?.alias || [],
    redirect: coursekAwDliuIS8Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/course.vue").then(m => m.default || m)
  },
  {
    name: gradencuekwbx87Meta?.name ?? "account-grade",
    path: gradencuekwbx87Meta?.path ?? "/account/grade",
    meta: gradencuekwbx87Meta || {},
    alias: gradencuekwbx87Meta?.alias || [],
    redirect: gradencuekwbx87Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/grade.vue").then(m => m.default || m)
  },
  {
    name: info5xmVss52FyMeta?.name ?? "account-info",
    path: info5xmVss52FyMeta?.path ?? "/account/info",
    meta: info5xmVss52FyMeta || {},
    alias: info5xmVss52FyMeta?.alias || [],
    redirect: info5xmVss52FyMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/info.vue").then(m => m.default || m)
  },
  {
    name: invoiceC6tr0EJgaHMeta?.name ?? "account-invoice",
    path: invoiceC6tr0EJgaHMeta?.path ?? "/account/invoice",
    meta: invoiceC6tr0EJgaHMeta || {},
    alias: invoiceC6tr0EJgaHMeta?.alias || [],
    redirect: invoiceC6tr0EJgaHMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/invoice.vue").then(m => m.default || m)
  },
  {
    name: indexOFK6ypyB9gMeta?.name ?? "account-message",
    path: indexOFK6ypyB9gMeta?.path ?? "/account/message",
    meta: indexOFK6ypyB9gMeta || {},
    alias: indexOFK6ypyB9gMeta?.alias || [],
    redirect: indexOFK6ypyB9gMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: note7etsUKwVwmMeta?.name ?? "account-note",
    path: note7etsUKwVwmMeta?.path ?? "/account/note",
    meta: note7etsUKwVwmMeta || {},
    alias: note7etsUKwVwmMeta?.alias || [],
    redirect: note7etsUKwVwmMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/note.vue").then(m => m.default || m)
  },
  {
    name: indexXb741NmZr2Meta?.name ?? "account-order",
    path: indexXb741NmZr2Meta?.path ?? "/account/order",
    meta: indexXb741NmZr2Meta || {},
    alias: indexXb741NmZr2Meta?.alias || [],
    redirect: indexXb741NmZr2Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: paperOVqv5zJwODMeta?.name ?? "account-paper",
    path: paperOVqv5zJwODMeta?.path ?? "/account/paper",
    meta: paperOVqv5zJwODMeta || {},
    alias: paperOVqv5zJwODMeta?.alias || [],
    redirect: paperOVqv5zJwODMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/paper.vue").then(m => m.default || m)
  },
  {
    name: index1ACcFrA4oEMeta?.name ?? "account-promoter",
    path: index1ACcFrA4oEMeta?.path ?? "/account/promoter",
    meta: index1ACcFrA4oEMeta || {},
    alias: index1ACcFrA4oEMeta?.alias || [],
    redirect: index1ACcFrA4oEMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/promoter/index.vue").then(m => m.default || m)
  },
  {
    name: registrationPaMgakQHc4Meta?.name ?? "account-registration",
    path: registrationPaMgakQHc4Meta?.path ?? "/account/registration",
    meta: registrationPaMgakQHc4Meta || {},
    alias: registrationPaMgakQHc4Meta?.alias || [],
    redirect: registrationPaMgakQHc4Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/registration.vue").then(m => m.default || m)
  },
  {
    name: coinInRxgNtAnzMeta?.name ?? "account-virtual-coin",
    path: coinInRxgNtAnzMeta?.path ?? "/account/virtual/coin",
    meta: coinInRxgNtAnzMeta || {},
    alias: coinInRxgNtAnzMeta?.alias || [],
    redirect: coinInRxgNtAnzMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/account/virtual/coin.vue").then(m => m.default || m)
  },
  {
    name: teacherwe8s6GOMwKMeta?.name ?? "apply-teacher",
    path: teacherwe8s6GOMwKMeta?.path ?? "/apply/teacher",
    meta: teacherwe8s6GOMwKMeta || {},
    alias: teacherwe8s6GOMwKMeta?.alias || [],
    redirect: teacherwe8s6GOMwKMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/apply/teacher.vue").then(m => m.default || m)
  },
  {
    name: listDUjgn8AXnhMeta?.name ?? "article-list",
    path: listDUjgn8AXnhMeta?.path ?? "/article/list",
    meta: listDUjgn8AXnhMeta || {},
    alias: listDUjgn8AXnhMeta?.alias || [],
    redirect: listDUjgn8AXnhMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/article/list.vue").then(m => m.default || m)
  },
  {
    name: viewKzhi4cog8BMeta?.name ?? "article-view",
    path: viewKzhi4cog8BMeta?.path ?? "/article/view",
    meta: viewKzhi4cog8BMeta || {},
    alias: viewKzhi4cog8BMeta?.alias || [],
    redirect: viewKzhi4cog8BMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/article/view.vue").then(m => m.default || m)
  },
  {
    name: analysisJA5FCKHRhRMeta?.name ?? "book-analysis",
    path: analysisJA5FCKHRhRMeta?.path ?? "/book/analysis",
    meta: analysisJA5FCKHRhRMeta || {},
    alias: analysisJA5FCKHRhRMeta?.alias || [],
    redirect: analysisJA5FCKHRhRMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/book/analysis.vue").then(m => m.default || m)
  },
  {
    name: exam7gN3JjvDp2Meta?.name ?? "book-exam",
    path: exam7gN3JjvDp2Meta?.path ?? "/book/exam",
    meta: exam7gN3JjvDp2Meta || {},
    alias: exam7gN3JjvDp2Meta?.alias || [],
    redirect: exam7gN3JjvDp2Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/book/exam.vue").then(m => m.default || m)
  },
  {
    name: listtErTzGDIfGMeta?.name ?? "book-list",
    path: listtErTzGDIfGMeta?.path ?? "/book/list",
    meta: listtErTzGDIfGMeta || {},
    alias: listtErTzGDIfGMeta?.alias || [],
    redirect: listtErTzGDIfGMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/book/list.vue").then(m => m.default || m)
  },
  {
    name: preview8aP9HsiuVFMeta?.name ?? "book-preview",
    path: preview8aP9HsiuVFMeta?.path ?? "/book/preview",
    meta: preview8aP9HsiuVFMeta || {},
    alias: preview8aP9HsiuVFMeta?.alias || [],
    redirect: preview8aP9HsiuVFMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/book/preview.vue").then(m => m.default || m)
  },
  {
    name: resultsGTjaN7YiFMeta?.name ?? "book-result",
    path: resultsGTjaN7YiFMeta?.path ?? "/book/result",
    meta: resultsGTjaN7YiFMeta || {},
    alias: resultsGTjaN7YiFMeta?.alias || [],
    redirect: resultsGTjaN7YiFMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/book/result.vue").then(m => m.default || m)
  },
  {
    name: view0E4xuMQMVfMeta?.name ?? "book-view",
    path: view0E4xuMQMVfMeta?.path ?? "/book/view",
    meta: view0E4xuMQMVfMeta || {},
    alias: view0E4xuMQMVfMeta?.alias || [],
    redirect: view0E4xuMQMVfMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/book/view.vue").then(m => m.default || m)
  },
  {
    name: audiotXHwCUedinMeta?.name ?? "course-audio",
    path: audiotXHwCUedinMeta?.path ?? "/course/audio",
    meta: audiotXHwCUedinMeta || {},
    alias: audiotXHwCUedinMeta?.alias || [],
    redirect: audiotXHwCUedinMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/audio.vue").then(m => m.default || m)
  },
  {
    name: combineRWERwd3xfgMeta?.name ?? "course-combine",
    path: combineRWERwd3xfgMeta?.path ?? "/course/combine",
    meta: combineRWERwd3xfgMeta || {},
    alias: combineRWERwd3xfgMeta?.alias || [],
    redirect: combineRWERwd3xfgMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/combine.vue").then(m => m.default || m)
  },
  {
    name: graphiciUjibSjHvUMeta?.name ?? "course-graphic",
    path: graphiciUjibSjHvUMeta?.path ?? "/course/graphic",
    meta: graphiciUjibSjHvUMeta || {},
    alias: graphiciUjibSjHvUMeta?.alias || [],
    redirect: graphiciUjibSjHvUMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/graphic.vue").then(m => m.default || m)
  },
  {
    name: listpP32mPzaz7Meta?.name ?? "course-list",
    path: listpP32mPzaz7Meta?.path ?? "/course/list",
    meta: listpP32mPzaz7Meta || {},
    alias: listpP32mPzaz7Meta?.alias || [],
    redirect: listpP32mPzaz7Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/list.vue").then(m => m.default || m)
  },
  {
    name: live4WZhrr9zseMeta?.name ?? "course-live",
    path: live4WZhrr9zseMeta?.path ?? "/course/live",
    meta: live4WZhrr9zseMeta || {},
    alias: live4WZhrr9zseMeta?.alias || [],
    redirect: live4WZhrr9zseMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/live.vue").then(m => m.default || m)
  },
  {
    name: singleaaAIuZK3ONMeta?.name ?? "course-single",
    path: singleaaAIuZK3ONMeta?.path ?? "/course/single",
    meta: singleaaAIuZK3ONMeta || {},
    alias: singleaaAIuZK3ONMeta?.alias || [],
    redirect: singleaaAIuZK3ONMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/single.vue").then(m => m.default || m)
  },
  {
    name: subjectNzzaDHgAPEMeta?.name ?? "course-subject",
    path: subjectNzzaDHgAPEMeta?.path ?? "/course/subject",
    meta: subjectNzzaDHgAPEMeta || {},
    alias: subjectNzzaDHgAPEMeta?.alias || [],
    redirect: subjectNzzaDHgAPEMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/subject.vue").then(m => m.default || m)
  },
  {
    name: topicF3XUswmGf7Meta?.name ?? "course-topic",
    path: topicF3XUswmGf7Meta?.path ?? "/course/topic",
    meta: topicF3XUswmGf7Meta || {},
    alias: topicF3XUswmGf7Meta?.alias || [],
    redirect: topicF3XUswmGf7Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/topic.vue").then(m => m.default || m)
  },
  {
    name: videobZnJfT3coYMeta?.name ?? "course-video",
    path: videobZnJfT3coYMeta?.path ?? "/course/video",
    meta: videobZnJfT3coYMeta || {},
    alias: videobZnJfT3coYMeta?.alias || [],
    redirect: videobZnJfT3coYMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/course/video.vue").then(m => m.default || m)
  },
  {
    name: analysisGoq6pFGQBiMeta?.name ?? "exam-analysis",
    path: analysisGoq6pFGQBiMeta?.path ?? "/exam/analysis",
    meta: analysisGoq6pFGQBiMeta || {},
    alias: analysisGoq6pFGQBiMeta?.alias || [],
    redirect: analysisGoq6pFGQBiMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/exam/analysis.vue").then(m => m.default || m)
  },
  {
    name: emigratedPlXFX6wsehMeta?.name ?? "exam-emigrated",
    path: emigratedPlXFX6wsehMeta?.path ?? "/exam/emigrated",
    meta: emigratedPlXFX6wsehMeta || {},
    alias: emigratedPlXFX6wsehMeta?.alias || [],
    redirect: emigratedPlXFX6wsehMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/exam/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexgpzaksNXNjMeta?.name ?? "exam",
    path: indexgpzaksNXNjMeta?.path ?? "/exam",
    meta: indexgpzaksNXNjMeta || {},
    alias: indexgpzaksNXNjMeta?.alias || [],
    redirect: indexgpzaksNXNjMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/exam/index.vue").then(m => m.default || m)
  },
  {
    name: list1YkTvnZPKeMeta?.name ?? "exam-list",
    path: list1YkTvnZPKeMeta?.path ?? "/exam/list",
    meta: list1YkTvnZPKeMeta || {},
    alias: list1YkTvnZPKeMeta?.alias || [],
    redirect: list1YkTvnZPKeMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/exam/list.vue").then(m => m.default || m)
  },
  {
    name: resultyRvZlR5ZKTMeta?.name ?? "exam-result",
    path: resultyRvZlR5ZKTMeta?.path ?? "/exam/result",
    meta: resultyRvZlR5ZKTMeta || {},
    alias: resultyRvZlR5ZKTMeta?.alias || [],
    redirect: resultyRvZlR5ZKTMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/exam/result.vue").then(m => m.default || m)
  },
  {
    name: startExamcJ7o7lHhnTMeta?.name ?? "exam-startExam",
    path: startExamcJ7o7lHhnTMeta?.path ?? "/exam/startExam",
    meta: startExamcJ7o7lHhnTMeta || {},
    alias: startExamcJ7o7lHhnTMeta?.alias || [],
    redirect: startExamcJ7o7lHhnTMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/exam/startExam.vue").then(m => m.default || m)
  },
  {
    name: homeworkvE4b8xvn3jMeta?.name ?? "grade-homework",
    path: homeworkvE4b8xvn3jMeta?.path ?? "/grade/homework",
    meta: homeworkvE4b8xvn3jMeta || {},
    alias: homeworkvE4b8xvn3jMeta?.alias || [],
    redirect: homeworkvE4b8xvn3jMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/grade/homework.vue").then(m => m.default || m)
  },
  {
    name: listqmjfxhIvWkMeta?.name ?? "grade-list",
    path: listqmjfxhIvWkMeta?.path ?? "/grade/list",
    meta: listqmjfxhIvWkMeta || {},
    alias: listqmjfxhIvWkMeta?.alias || [],
    redirect: listqmjfxhIvWkMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/grade/list.vue").then(m => m.default || m)
  },
  {
    name: practicenM5sndZTG9Meta?.name ?? "grade-practice",
    path: practicenM5sndZTG9Meta?.path ?? "/grade/practice",
    meta: practicenM5sndZTG9Meta || {},
    alias: practicenM5sndZTG9Meta?.alias || [],
    redirect: practicenM5sndZTG9Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/grade/practice.vue").then(m => m.default || m)
  },
  {
    name: view0mTTbTUgzlMeta?.name ?? "grade-view",
    path: view0mTTbTUgzlMeta?.path ?? "/grade/view",
    meta: view0mTTbTUgzlMeta || {},
    alias: view0mTTbTUgzlMeta?.alias || [],
    redirect: view0mTTbTUgzlMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/grade/view.vue").then(m => m.default || m)
  },
  {
    name: indexN9fhr6eGyOMeta?.name ?? "index",
    path: indexN9fhr6eGyOMeta?.path ?? "/",
    meta: indexN9fhr6eGyOMeta || {},
    alias: indexN9fhr6eGyOMeta?.alias || [],
    redirect: indexN9fhr6eGyOMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agreementHLlDcWpgXSMeta?.name ?? "login-agreement",
    path: agreementHLlDcWpgXSMeta?.path ?? "/login/agreement",
    meta: agreementHLlDcWpgXSMeta || {},
    alias: agreementHLlDcWpgXSMeta?.alias || [],
    redirect: agreementHLlDcWpgXSMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/login/agreement.vue").then(m => m.default || m)
  },
  {
    name: indexUmxwYXuAO5Meta?.name ?? "login",
    path: indexUmxwYXuAO5Meta?.path ?? "/login",
    meta: indexUmxwYXuAO5Meta || {},
    alias: indexUmxwYXuAO5Meta?.alias || [],
    redirect: indexUmxwYXuAO5Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: emigratedoqAer11npJMeta?.name ?? "paper-emigrated",
    path: emigratedoqAer11npJMeta?.path ?? "/paper/emigrated",
    meta: emigratedoqAer11npJMeta || {},
    alias: emigratedoqAer11npJMeta?.alias || [],
    redirect: emigratedoqAer11npJMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/paper/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indextikMNGEkPCMeta?.name ?? "paper",
    path: indextikMNGEkPCMeta?.path ?? "/paper",
    meta: indextikMNGEkPCMeta || {},
    alias: indextikMNGEkPCMeta?.alias || [],
    redirect: indextikMNGEkPCMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/paper/index.vue").then(m => m.default || m)
  },
  {
    name: listewfXYxYqyXMeta?.name ?? "paper-list",
    path: listewfXYxYqyXMeta?.path ?? "/paper/list",
    meta: listewfXYxYqyXMeta || {},
    alias: listewfXYxYqyXMeta?.alias || [],
    redirect: listewfXYxYqyXMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/paper/list.vue").then(m => m.default || m)
  },
  {
    name: viewVjOteSB63mMeta?.name ?? "paper-view",
    path: viewVjOteSB63mMeta?.path ?? "/paper/view",
    meta: viewVjOteSB63mMeta || {},
    alias: viewVjOteSB63mMeta?.alias || [],
    redirect: viewVjOteSB63mMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/paper/view.vue").then(m => m.default || m)
  },
  {
    name: buyv1TkGCTb4VMeta?.name ?? "pay-buy",
    path: buyv1TkGCTb4VMeta?.path ?? "/pay/buy",
    meta: buyv1TkGCTb4VMeta || {},
    alias: buyv1TkGCTb4VMeta?.alias || [],
    redirect: buyv1TkGCTb4VMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/pay/buy.vue").then(m => m.default || m)
  },
  {
    name: paySuccessAxSq1vyvrjMeta?.name ?? "pay-paySuccess",
    path: paySuccessAxSq1vyvrjMeta?.path ?? "/pay/paySuccess",
    meta: paySuccessAxSq1vyvrjMeta || {},
    alias: paySuccessAxSq1vyvrjMeta?.alias || [],
    redirect: paySuccessAxSq1vyvrjMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/pay/paySuccess.vue").then(m => m.default || m)
  },
  {
    name: indexcOdh6QjVaKMeta?.name ?? "popularize-course",
    path: indexcOdh6QjVaKMeta?.path ?? "/popularize/course",
    meta: indexcOdh6QjVaKMeta || {},
    alias: indexcOdh6QjVaKMeta?.alias || [],
    redirect: indexcOdh6QjVaKMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/course/index.vue").then(m => m.default || m)
  },
  {
    name: courseListjjzltD0vwFMeta?.name ?? "popularize-courseList",
    path: courseListjjzltD0vwFMeta?.path ?? "/popularize/courseList",
    meta: courseListjjzltD0vwFMeta || {},
    alias: courseListjjzltD0vwFMeta?.alias || [],
    redirect: courseListjjzltD0vwFMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/courseList.vue").then(m => m.default || m)
  },
  {
    name: indexsLGhDQ2dvVMeta?.name ?? "popularize-grade",
    path: indexsLGhDQ2dvVMeta?.path ?? "/popularize/grade",
    meta: indexsLGhDQ2dvVMeta || {},
    alias: indexsLGhDQ2dvVMeta?.alias || [],
    redirect: indexsLGhDQ2dvVMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/grade/index.vue").then(m => m.default || m)
  },
  {
    name: gradeListf8F6WarG8QMeta?.name ?? "popularize-gradeList",
    path: gradeListf8F6WarG8QMeta?.path ?? "/popularize/gradeList",
    meta: gradeListf8F6WarG8QMeta || {},
    alias: gradeListf8F6WarG8QMeta?.alias || [],
    redirect: gradeListf8F6WarG8QMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/gradeList.vue").then(m => m.default || m)
  },
  {
    name: listSk19kfItD7Meta?.name ?? "popularize-list",
    path: listSk19kfItD7Meta?.path ?? "/popularize/list",
    meta: listSk19kfItD7Meta || {},
    alias: listSk19kfItD7Meta?.alias || [],
    redirect: listSk19kfItD7Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/list.vue").then(m => m.default || m)
  },
  {
    name: indexj62bAwnJojMeta?.name ?? "popularize-paper",
    path: indexj62bAwnJojMeta?.path ?? "/popularize/paper",
    meta: indexj62bAwnJojMeta || {},
    alias: indexj62bAwnJojMeta?.alias || [],
    redirect: indexj62bAwnJojMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/paper/index.vue").then(m => m.default || m)
  },
  {
    name: paperListjq23FGeTMoMeta?.name ?? "popularize-paperList",
    path: paperListjq23FGeTMoMeta?.path ?? "/popularize/paperList",
    meta: paperListjq23FGeTMoMeta || {},
    alias: paperListjq23FGeTMoMeta?.alias || [],
    redirect: paperListjq23FGeTMoMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/paperList.vue").then(m => m.default || m)
  },
  {
    name: vipCardOaQXsBXDs6Meta?.name ?? "popularize-vipCard",
    path: vipCardOaQXsBXDs6Meta?.path ?? "/popularize/vipCard",
    meta: vipCardOaQXsBXDs6Meta || {},
    alias: vipCardOaQXsBXDs6Meta?.alias || [],
    redirect: vipCardOaQXsBXDs6Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/popularize/vipCard.vue").then(m => m.default || m)
  },
  {
    name: analysisDjOyyYF5jtMeta?.name ?? "practice-analysis",
    path: analysisDjOyyYF5jtMeta?.path ?? "/practice/analysis",
    meta: analysisDjOyyYF5jtMeta || {},
    alias: analysisDjOyyYF5jtMeta?.alias || [],
    redirect: analysisDjOyyYF5jtMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/analysis.vue").then(m => m.default || m)
  },
  {
    name: dailyEB71bbXAMTMeta?.name ?? "practice-daily",
    path: dailyEB71bbXAMTMeta?.path ?? "/practice/daily",
    meta: dailyEB71bbXAMTMeta || {},
    alias: dailyEB71bbXAMTMeta?.alias || [],
    redirect: dailyEB71bbXAMTMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/daily.vue").then(m => m.default || m)
  },
  {
    name: dailyProblemOfI90GMebZMeta?.name ?? "practice-dailyProblem",
    path: dailyProblemOfI90GMebZMeta?.path ?? "/practice/dailyProblem",
    meta: dailyProblemOfI90GMebZMeta || {},
    alias: dailyProblemOfI90GMebZMeta?.alias || [],
    redirect: dailyProblemOfI90GMebZMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/dailyProblem.vue").then(m => m.default || m)
  },
  {
    name: errorUl9qftfQPfMeta?.name ?? "practice-error",
    path: errorUl9qftfQPfMeta?.path ?? "/practice/error",
    meta: errorUl9qftfQPfMeta || {},
    alias: errorUl9qftfQPfMeta?.alias || [],
    redirect: errorUl9qftfQPfMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/error.vue").then(m => m.default || m)
  },
  {
    name: errorAnalysisVWUAx8hEMRMeta?.name ?? "practice-errorAnalysis",
    path: errorAnalysisVWUAx8hEMRMeta?.path ?? "/practice/errorAnalysis",
    meta: errorAnalysisVWUAx8hEMRMeta || {},
    alias: errorAnalysisVWUAx8hEMRMeta?.alias || [],
    redirect: errorAnalysisVWUAx8hEMRMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/errorAnalysis.vue").then(m => m.default || m)
  },
  {
    name: errorProblemWBHg9q36WUMeta?.name ?? "practice-errorProblem",
    path: errorProblemWBHg9q36WUMeta?.path ?? "/practice/errorProblem",
    meta: errorProblemWBHg9q36WUMeta || {},
    alias: errorProblemWBHg9q36WUMeta?.alias || [],
    redirect: errorProblemWBHg9q36WUMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/errorProblem.vue").then(m => m.default || m)
  },
  {
    name: errorResultPbtEBIAKYtMeta?.name ?? "practice-errorResult",
    path: errorResultPbtEBIAKYtMeta?.path ?? "/practice/errorResult",
    meta: errorResultPbtEBIAKYtMeta || {},
    alias: errorResultPbtEBIAKYtMeta?.alias || [],
    redirect: errorResultPbtEBIAKYtMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/errorResult.vue").then(m => m.default || m)
  },
  {
    name: resultNrtLH5hbM9Meta?.name ?? "practice-result",
    path: resultNrtLH5hbM9Meta?.path ?? "/practice/result",
    meta: resultNrtLH5hbM9Meta || {},
    alias: resultNrtLH5hbM9Meta?.alias || [],
    redirect: resultNrtLH5hbM9Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/result.vue").then(m => m.default || m)
  },
  {
    name: startPracticeTAXslAyg1gMeta?.name ?? "practice-startPractice",
    path: startPracticeTAXslAyg1gMeta?.path ?? "/practice/startPractice",
    meta: startPracticeTAXslAyg1gMeta || {},
    alias: startPracticeTAXslAyg1gMeta?.alias || [],
    redirect: startPracticeTAXslAyg1gMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/startPractice.vue").then(m => m.default || m)
  },
  {
    name: viewF4CWr71QCOMeta?.name ?? "practice-view",
    path: viewF4CWr71QCOMeta?.path ?? "/practice/view",
    meta: viewF4CWr71QCOMeta || {},
    alias: viewF4CWr71QCOMeta?.alias || [],
    redirect: viewF4CWr71QCOMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/practice/view.vue").then(m => m.default || m)
  },
  {
    name: listctwI3ZCQ4kMeta?.name ?? "press-list",
    path: listctwI3ZCQ4kMeta?.path ?? "/press/list",
    meta: listctwI3ZCQ4kMeta || {},
    alias: listctwI3ZCQ4kMeta?.alias || [],
    redirect: listctwI3ZCQ4kMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/press/list.vue").then(m => m.default || m)
  },
  {
    name: viewtW0PAEvfE8Meta?.name ?? "press-view",
    path: viewtW0PAEvfE8Meta?.path ?? "/press/view",
    meta: viewtW0PAEvfE8Meta || {},
    alias: viewtW0PAEvfE8Meta?.alias || [],
    redirect: viewtW0PAEvfE8Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/press/view.vue").then(m => m.default || m)
  },
  {
    name: indexBON6EpZfSkMeta?.name ?? "recharge",
    path: indexBON6EpZfSkMeta?.path ?? "/recharge",
    meta: indexBON6EpZfSkMeta || {},
    alias: indexBON6EpZfSkMeta?.alias || [],
    redirect: indexBON6EpZfSkMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/recharge/index.vue").then(m => m.default || m)
  },
  {
    name: indexrztUJArRM6Meta?.name ?? "register",
    path: indexrztUJArRM6Meta?.path ?? "/register",
    meta: indexrztUJArRM6Meta || {},
    alias: indexrztUJArRM6Meta?.alias || [],
    redirect: indexrztUJArRM6Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: examghIJ8hfmb6Meta?.name ?? "registration-exam",
    path: examghIJ8hfmb6Meta?.path ?? "/registration/exam",
    meta: examghIJ8hfmb6Meta || {},
    alias: examghIJ8hfmb6Meta?.alias || [],
    redirect: examghIJ8hfmb6Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/registration/exam.vue").then(m => m.default || m)
  },
  {
    name: result8qL7nCW1WXMeta?.name ?? "registration-result",
    path: result8qL7nCW1WXMeta?.path ?? "/registration/result",
    meta: result8qL7nCW1WXMeta || {},
    alias: result8qL7nCW1WXMeta?.alias || [],
    redirect: result8qL7nCW1WXMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/registration/result.vue").then(m => m.default || m)
  },
  {
    name: startExamDOCkxF6QeDMeta?.name ?? "registration-startExam",
    path: startExamDOCkxF6QeDMeta?.path ?? "/registration/startExam",
    meta: startExamDOCkxF6QeDMeta || {},
    alias: startExamDOCkxF6QeDMeta?.alias || [],
    redirect: startExamDOCkxF6QeDMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/registration/startExam.vue").then(m => m.default || m)
  },
  {
    name: viewJCHH2RZ5q3Meta?.name ?? "registration-view",
    path: viewJCHH2RZ5q3Meta?.path ?? "/registration/view",
    meta: viewJCHH2RZ5q3Meta || {},
    alias: viewJCHH2RZ5q3Meta?.alias || [],
    redirect: viewJCHH2RZ5q3Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/registration/view.vue").then(m => m.default || m)
  },
  {
    name: index0tpStnYnmMMeta?.name ?? "resetpwd",
    path: index0tpStnYnmMMeta?.path ?? "/resetpwd",
    meta: index0tpStnYnmMMeta || {},
    alias: index0tpStnYnmMMeta?.alias || [],
    redirect: index0tpStnYnmMMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/resetpwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexTvCAQQdwP3Meta?.name ?? "search",
    path: indexTvCAQQdwP3Meta?.path ?? "/search",
    meta: indexTvCAQQdwP3Meta || {},
    alias: indexTvCAQQdwP3Meta?.alias || [],
    redirect: indexTvCAQQdwP3Meta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: listiAoQhqcxjwMeta?.name ?? "tch-list",
    path: listiAoQhqcxjwMeta?.path ?? "/tch/list",
    meta: listiAoQhqcxjwMeta || {},
    alias: listiAoQhqcxjwMeta?.alias || [],
    redirect: listiAoQhqcxjwMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/tch/list.vue").then(m => m.default || m)
  },
  {
    name: viewpRkYBeN6DbMeta?.name ?? "tch-view",
    path: viewpRkYBeN6DbMeta?.path ?? "/tch/view",
    meta: viewpRkYBeN6DbMeta || {},
    alias: viewpRkYBeN6DbMeta?.alias || [],
    redirect: viewpRkYBeN6DbMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/tch/view.vue").then(m => m.default || m)
  },
  {
    name: index2UpHV38OKrMeta?.name ?? "vip",
    path: index2UpHV38OKrMeta?.path ?? "/vip",
    meta: index2UpHV38OKrMeta || {},
    alias: index2UpHV38OKrMeta?.alias || [],
    redirect: index2UpHV38OKrMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/vip/index.vue").then(m => m.default || m)
  },
  {
    name: success89umAzd0FAMeta?.name ?? "wx-success",
    path: success89umAzd0FAMeta?.path ?? "/wx/success",
    meta: success89umAzd0FAMeta || {},
    alias: success89umAzd0FAMeta?.alias || [],
    redirect: success89umAzd0FAMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/wx/success.vue").then(m => m.default || m)
  },
  {
    name: wx3fM6Jf4G3EMeta?.name ?? "wx-wx",
    path: wx3fM6Jf4G3EMeta?.path ?? "/wx/wx",
    meta: wx3fM6Jf4G3EMeta || {},
    alias: wx3fM6Jf4G3EMeta?.alias || [],
    redirect: wx3fM6Jf4G3EMeta?.redirect || undefined,
    component: () => import("E:/project/52read/education/roncoo-education-web/pages/wx/wx.vue").then(m => m.default || m)
  }
]