export const appHead = {"meta":[{"charset":"utf-8"},{"httpEquiv":"Content-Type","content":"text/html;charset=utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"stylesheet","href":"//at.alicdn.com/t/c/font_3161429_e30clvg9ycr.css"}],"style":[],"script":[{"src":"//lib.baomitu.com/jquery/3.5.1/jquery.min.js"},{"src":"//live-cdn.baijiayun.com/bplayer/latest/bplayer.js"},{"src":"//live-cdn.baijiayun.com/bplayer/latest/dep/ffplayer.js"},{"src":"//player.polyv.net/script/player.js"},{"src":"/tracking/tracking.js"},{"src":"/tracking/data/face.js"},{"src":"/tracking/data/eye.js"},{"src":"/wangEditor.min.js"},{"src":"/supervise.js"}],"noscript":[],"title":""}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null

export const devRootDir = null