import { Editor, Node, Transforms, Range } from 'slate'
import { createEditor, i18nChangeLanguage, Boot, DomEditor, SlateTransforms, SlatePath, SlateElement, SlateEditor } from '@wangeditor/editor'

// 注册扩展菜单
import tippy, { inlinePositioning } from 'tippy.js'
import MarkdownModule from '@wangeditor/plugin-md'
import linkCardModule from '@wangeditor/plugin-link-card'
window._book = {
  Boot,
  Node,
  Range,
  tippy,
  Editor,
  DomEditor,
  SlatePath,
  Transforms,
  SlateEditor,
  SlateElement,
  MarkdownModule,
  linkCardModule,
  SlateTransforms,
  inlinePositioning,
  createEditor,
  i18nChangeLanguage
}
export default defineNuxtPlugin(nuxtApp => {})
