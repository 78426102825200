export function handleNavigate (options, isTo = false) {
  if (!options) return ''
  let url = ''
  const { targetType, targetAddr, courseType, targetAddrType } = options
  if (!targetType || targetType === 99) return 'javascript:;'
  if (targetType === 1) {
    const functionEnum = {
      1: '/',
      2: '/course/list',
      3: '/grade/list',
      4: '/tch/list',
      5: '/article/list',
      6: '/account/info',
      7: '/paper/list',
      8: '/registration/exam',
      9: '/book/list',
      10: '/press/list'
    }
    if (functionEnum[targetAddr]) {
      url = functionEnum[targetAddr]
    } else {
      url = '/'
    }
  }
  // 课程
  if (targetType === 2) {
    if (targetAddr) {
      if (courseType || targetAddrType) {
        const type = courseType || targetAddrType
        // (1:直播、2:视频、3:音频、4:图文、5:专题课、6:套餐 )
        const typeObj = {
          1: 'live',
          2: 'video',
          3: 'audio',
          4: 'graphic',
          5: 'subject',
          6: 'topic'
        }
        if (type) {
          url = `/course/${typeObj[type]}?id=${targetAddr}`
        }
      }
    } else {
      url = '/course/list'
    }
  }

  if ([3, 5, 8, 10].includes(targetType)) {
    // 3.课程分类,5.班级分类,8.资讯分类,10.试卷分类
    const targetEnum = {
      3: '/course/list',
      5: '/grade/list',
      8: '/article/list',
      10: '/paper/list'
    }
    if (targetEnum[targetType]) {
      url = `${targetEnum[targetType]}?categoryId=${targetAddr}`
    } else {
      url = '/'
    }
  }

  if ([4, 6, 7].includes(targetType)) {
    // 4.班级，6.教师，7.资讯
    const targetEnum = { 4: 'grade', 6: 'teacher', 7: 'article' }
    if (targetAddr) {
      url = `/${targetEnum[targetType]}/view?id=${targetAddr}`
    } else {
      url = `/${targetEnum[targetType]}/list`
    }
  }
  // 教材
  if (targetType === 11) {
    if (targetAddr) {
      url = `/book/view?id=${targetAddr}`
    } else {
      url = `/book/list`
    }
  }
  // 自定义链接
  if (targetType === 90) {
    if (targetAddr) {
      url = targetAddr
    }
  }
  if (isTo) {
    return navigateTo(url)
  } else {
    return url
  }
}

// 获取url中的参数
export function getUrlParam (path, name) {
  const reg = new RegExp('(^|\\?|&)' + name + '=([^&]*)(\\s|&|$)', 'i')
  if (reg.test(path)) { return unescape(RegExp.$2.replace(/\+/g, ' ')) }
  return ''
}


/**
 * @param 富文本
 * @returns {string} 没有标签的文字
 */
export function parseHTML(html) {
  return html.replace(/<(\S*?)[^>]*>.*?|<.*? \/>/g, '')
}

/**
 * 深拷贝
 * @param param
 * @returns {any}
 */
export function deepCopy(param) {
  return JSON.parse(JSON.stringify(param))
}

/**
 * 获取时长
 * @param mss
 * @returns {string}
 */
export const formatDuring = (mss) => {
  // const days = parseInt(mss / (1000 * 60 * 60 * 24));
  const hours = parseInt((mss / (1000 * 60 * 60)))
  const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = parseInt((mss % (1000 * 60)) / 1000)
  return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
}
