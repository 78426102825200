import plugin_vue3_A0OWXRrUgq from "E:/project/52read/education/roncoo-education-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "E:/project/52read/education/roncoo-education-web/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "E:/project/52read/education/roncoo-education-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "E:/project/52read/education/roncoo-education-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "E:/project/52read/education/roncoo-education-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "E:/project/52read/education/roncoo-education-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "E:/project/52read/education/roncoo-education-web/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import book_client_rIZ1lBsKIx from "E:/project/52read/education/roncoo-education-web/plugins/book.client.js";
import element_plus_elM4AXquKi from "E:/project/52read/education/roncoo-education-web/plugins/element-plus.js";
import global_dY5SAi3BsR from "E:/project/52read/education/roncoo-education-web/plugins/global.js";
import hacktimer_client_JofzMSlUjT from "E:/project/52read/education/roncoo-education-web/plugins/hacktimer.client.js";
import sentry_client_GoGQuZo4Et from "E:/project/52read/education/roncoo-education-web/plugins/sentry.client.js";
import wangeditor_client_y7u3ur19Hv from "E:/project/52read/education/roncoo-education-web/plugins/wangeditor.client.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  book_client_rIZ1lBsKIx,
  element_plus_elM4AXquKi,
  global_dY5SAi3BsR,
  hacktimer_client_JofzMSlUjT,
  sentry_client_GoGQuZo4Et,
  wangeditor_client_y7u3ur19Hv
]